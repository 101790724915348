import flatMap from "lodash/flatMap"

import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"
import UnifiedMatrixController from "@store/unified-matrix/unified-matrix.controller"

export const initCustomFunctions = (
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  initThemeFunction("THEME", matrixStore, unifiedMatrixController)

  initAccountPlanFunction("ACCOUNTPLAN", matrixStore, unifiedMatrixController)

  initProductPositioningFunction(
    "PRODUCTPOSITIONING",
    matrixStore,
    unifiedMatrixController
  )

  initDocumentComplianceFunction(
    "DOCUMENTCOMPLIANCE",
    matrixStore,
    unifiedMatrixController
  )
}

export const initThemeFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction({
    name: functionName,
    description: "Generate themes for a Company by Category and Business Unit",
    arguments: [
      { displayName: "Company" },
      { displayName: "Category" },
      { displayName: "Business Unit", optional: true },
    ],
    transformArgs: (args: any[]) => {
      if (args.length > 3)
        throw new Error(
          `Function ${functionName} expects three arguments maximum`
        )

      if (args.length < 2)
        throw new Error(
          `Function ${functionName} expects at least two arguments`
        )

      if (typeof args[0] !== "string" || !args[0])
        throw new Error(
          `Function ${functionName} expects a string for the Company argument`
        )

      if (typeof args[1] !== "string" || !args[1])
        throw new Error(
          `Function ${functionName} expects a string for the Category argument`
        )

      if (!(args[2] == null || (typeof args[2] === "string" && !!args[2])))
        throw new Error(
          `Function ${functionName} expects a string for the Business Unit argument`
        )

      return args
    },
    handler: async (...args: [string, string, string | undefined]) => {
      const response = await unifiedMatrixController.generateThemes(...args)

      if (response.status === "FAILED")
        throw new Error("Failed to generate themes.")

      return response.data
    },
  })
}

export const initAccountPlanFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction({
    name: functionName,
    description: "Generate account plan for a company.",
    arguments: [
      { displayName: "Target Company" },
      { displayName: "Target Company's Theme" },
      { displayName: "My Company's Theme" },
    ],
    transformArgs: (args: any[]) => {
      if (args.length !== 3)
        throw new Error(
          `Function ${functionName} expects exactly three arguments`
        )

      if (typeof args[0] !== "string" || !args[0])
        throw new Error(
          `Function ${functionName} expects a string for the Target Company argument`
        )

      if (typeof args[1] !== "string" || !args[1])
        throw new Error(
          `Function ${functionName} expects a string for the Target Company's Theme argument`
        )

      if (typeof args[2] !== "string" || !args[2])
        throw new Error(
          `Function ${functionName} expects a string for the My Company's Theme argument`
        )

      return args
    },
    handler: async (...args: [string, string, string]) => {
      const response = await unifiedMatrixController.generateAccountPlan(
        ...args
      )

      if (response.status === "FAILED")
        throw new Error("Failed to generate account plan.")

      return response.data
    },
  })
}

export const initProductPositioningFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction({
    name: functionName,
    description:
      "Generate product positioning report for a target product and one or more alternate products.",
    arguments: [
      { displayName: "Application" },
      { displayName: "Target Product" },
      { displayName: "Alternate Product 1" },
      { displayName: "...Alternate Product N", optional: true },
    ],
    transformArgs: (args: any[]) => {
      if (args.length < 3)
        throw new Error(
          `Function ${functionName} expects at between three and seven arguments`
        )

      const application = args[0]

      if (typeof application !== "string" || !application)
        throw new Error(
          `Function ${functionName} expects a string for the Application argument`
        )

      const target = args[1]

      if (typeof target !== "string" || !target)
        throw new Error(
          `Function ${functionName} expects a string for the Target Product argument`
        )

      const altProducts = flatMap(args.slice(2))

      if (altProducts.length < 1)
        throw new Error(
          `Function ${functionName} expects at least one Alternate Product`
        )

      altProducts.forEach((it) => {
        if (typeof it !== "string" || !it)
          throw new Error(
            `Function ${functionName} expects a string for an Alternate Product argument`
          )
      })

      return [application, target, altProducts]
    },
    handler: async (...args: [string, string, string[]]) => {
      const response =
        await unifiedMatrixController.generateProductPositioningReport(...args)

      if (response.status === "FAILED")
        throw new Error("Failed to generate product positioning report.")

      return response.data
    },
  })
}

export const initDocumentComplianceFunction = (
  functionName: string,
  matrixStore: MatrixStore,
  unifiedMatrixController: UnifiedMatrixController
) => {
  matrixStore.editManager.functionManager.registerFunction({
    name: functionName,
    description:
      "Generate compliance report for an authority document and regulated document.",
    arguments: [
      { displayName: "Authority Document" },
      { displayName: "Regulated Document" },
    ],
    transformArgs: (args: any[]) => {
      if (args.length !== 2)
        throw new Error(
          `Function ${functionName} expects exactly two arguments`
        )

      if (typeof args[0] !== "string" || !args[0])
        throw new Error(
          `Function ${functionName} expects a string for the Authority Document argument`
        )

      if (typeof args[1] !== "string" || !args[1])
        throw new Error(
          `Function ${functionName} expects a string for the Regulated Document argument`
        )

      return args
    },
    handler: async (...args: [string, string]) => {
      const response =
        await unifiedMatrixController.generateDocumentComplianceReport(...args)

      if (response.status === "FAILED")
        throw new Error("Failed to generate document compliance report.")

      return response.data
    },
  })
}
