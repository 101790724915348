import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import NotFound from "@components/ui/NotFound/NotFound"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import Button from "@components/ui/Button/Button"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import { DataConnectorSourceName } from "@framework/types/upload"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import useDataConnectionContextMenu from "@pages/upload/connectors/Sources/hooks/useDataConnectionContextMenu"
import ContextMenu from "@pages/upload/components/DocumentUploadCard/ContextMenu"
import { DocumentIconType } from "@framework/types/utils"
import { IconName } from "@components/ui/Icon/IconName"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Text from "@components/ui/Typography/Text"

import DataConnectorAccountItem from "./DataConnectorAccountItem"
import ModalTitle from "../components/ModalTitle/ModalTitle"

import styles from "./index.module.sass"

export interface DataConnectorAccountModalProps {
  sourceName: DataConnectorSourceName
  icon?: DocumentIconType | IconName
  label: string
}

export const DataConnectorAccountsModal: React.FC<DataConnectorAccountModalProps> =
  observer(({ sourceName, icon, label }) => {
    const { contentManagerController } = useController()
    const { loadKibanaConnectorAccounts } = contentManagerController
    const modal = useModal(ModalsTypes.DATA_CONNECTOR_ACCOUNT_MODAL)
    const contentManagerConfigModal = useModal(
      ModalsTypes.CONTENT_MANAGER_CONFIG_FORM_MODAL
    )

    const { restrictionsStore: access, contentManagerStore } = useStore()

    const { kibanaConnectorAccounts, loadingKibanaConnectorAccounts } =
      contentManagerStore

    const desc = getDataSourceNodeContextProps(sourceName)

    const selectHandler = (accountId: string) => () => {
      modal.hideModal()
    }

    const handleConnect = () => {
      contentManagerConfigModal.showModal({ sourceName, icon, label })
    }

    const isReadonly = !access.canEditDataSource

    const contextMenuProps = useDataConnectionContextMenu({
      sourceName,
      isReadonly,
      afterAction: () => {},
    })

    useEffect(() => {
      loadKibanaConnectorAccounts(sourceName)
    }, [])

    return (
      <BaseModal
        className={styles.root}
        containerClassName={styles.container}
        title={
          <ModalTitle
            titleText={
              <div className={styles.titleContainer}>
                {icon && <DocumentIcon icon={icon} />}
                {label}
              </div>
            }
          />
        }
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button
                variant="outlined"
                size="medium"
                onClick={modal.hideModal}
              >
                Cancel
              </Button>
              <Button size="medium" color="primary" onClick={handleConnect}>
                Create New Account
              </Button>
            </div>
          }
        >
          <div className={styles.divider} />

          <List gutter="0" align="stretch" className={styles.list}>
            {!kibanaConnectorAccounts?.length ? (
              loadingKibanaConnectorAccounts ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>
                  There are none {desc.label}
                  <br />
                  connected yet
                </NotFound>
              )
            ) : (
              <>
                <Text variant="body2" className={styles.heading}>
                  Connected Accounts
                </Text>
                {kibanaConnectorAccounts?.map((it) => (
                  <React.Fragment key={it.id}>
                    <DataConnectorAccountItem
                      data={it}
                      onClick={selectHandler(it.id)}
                      icon={icon}
                      control={
                        !!contextMenuProps.options.length && (
                          <ContextMenu id={it.id} {...contextMenuProps} />
                        )
                      }
                    />
                    <div className={styles.divider} />
                  </React.Fragment>
                ))}
              </>
            )}
          </List>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default DataConnectorAccountsModal
