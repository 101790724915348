import React, { useEffect, useRef, useState, useCallback } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import {
  CategoryInfo,
  DataFilterKeys,
  ProductFilterKeys,
  QueryFilterCategory,
  QueryFilterData,
  SubFilterInfo,
} from "@framework/types/query-filter"
import { useController, useStore } from "@store"

import CategoryList from "./CategoryList"
import FilterList from "./FilterList"

import styles from "./ProductsSection.module.sass"

const findSubcategoryData = (
  filters: QueryFilterCategory | undefined,
  subcategoryId: string
): QueryFilterData | undefined => {
  return filters?.items.find((item) => item.id === subcategoryId)
}

const ProductsSection: React.FC = observer(() => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryInfo>()
  const { solutionsStore } = useStore()
  const { filtersToApply, availableFilters, isPaginationLoading } =
    solutionsStore
  const { solutionsController } = useController()
  const { checkAndApplyFilter, loadMoreFiltersData } = solutionsController
  const [subCategoryData, setSubCategoryData] = useState<QueryFilterData[]>([])
  const lastItemRef = useRef<HTMLDivElement | null>(null)
  const [subFilters, setSubFilters] = useState<SubFilterInfo[]>([])

  const observerCallback = useCallback(
    async (entries) => {
      if (
        entries[0].isIntersecting &&
        !selectedCategory?.subcategoryId &&
        !isPaginationLoading &&
        !selectedCategory?.queryFilterCategory?.noMoreData
      ) {
        await loadMoreFiltersData({
          category: selectedCategory?.name || "",
          mainCategory: selectedCategory?.parentCategory || "",
          page: (selectedCategory?.queryFilterCategory?.page || 1) + 1,
          pageSize: selectedCategory?.queryFilterCategory?.pageSize || 10,
        })
      }
    },
    [selectedCategory, isPaginationLoading, loadMoreFiltersData]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    })

    if (lastItemRef.current) {
      observer.observe(lastItemRef.current)
    }

    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current)
      }
    }
  }, [lastItemRef.current, observerCallback])

  const getFilterData = () => {
    const filterData: QueryFilterData[] =
      (selectedCategory?.parentCategory === "product"
        ? availableFilters?.product[selectedCategory?.name as ProductFilterKeys]
        : availableFilters?.data[selectedCategory?.name as DataFilterKeys]
      )?.items ?? []

    return filterData.map((tempFilter) => ({
      ...tempFilter,
      type: selectedCategory?.name,
    }))
  }

  useEffect(() => {
    if (selectedCategory?.subcategoryId && !isPaginationLoading) {
      const filters =
        availableFilters?.product[
          selectedCategory?.parentCategory as ProductFilterKeys
        ]
      setSubCategoryData(
        (
          findSubcategoryData(filters, selectedCategory.subcategoryId)?.items ||
          []
        ).map((item) => ({ ...item, type: selectedCategory?.parentCategory }))
      )
    }
  }, [selectedCategory?.subcategoryId, availableFilters, isPaginationLoading])

  const handleSubcategorySelect = async (filter: QueryFilterData) => {
    setSubCategoryData([])
    setSelectedCategory((prev) => ({
      parentCategory: "categories",
      name: filter.name,
      queryFilterCategory: prev?.queryFilterCategory,
      subcategoryId: filter.id,
      mainCategory: "product",
    }))

    await loadMoreFiltersData({
      category: "categories",
      mainCategory: "product",
      subCategoryId: filter.id,
    })
  }

  const handleContentSelect = async (
    filter: QueryFilterData,
    parentCategory: string
  ) => {
    setSubCategoryData([])
    setSelectedCategory((prev) => ({
      parentCategory,
      name: filter.name,
      queryFilterCategory: prev?.queryFilterCategory,
      subcategoryId: filter.id,
      mainCategory: "data",
    }))

    await loadMoreFiltersData({
      category: parentCategory,
      mainCategory: "data",
      subCategoryId: filter.id,
      page: 1,
      pageSize: 100,
    })
  }

  const handleCategoryBack = () => {
    if (subFilters.length > 0) {
      setSubFilters((prev) => prev.slice(0, -1))
    } else {
      setSelectedCategory(undefined)
    }
  }

  const subFilterSelect = async (filter: QueryFilterData, parent: string) => {
    const currentFilter = { filterId: filter.id, heading: filter.name, parent }

    setSubFilters((p) => [...p, currentFilter])

    const data = await loadMoreFiltersData({
      subCategoryId: filter.id,
      category: parent,
      mainCategory: selectedCategory?.mainCategory || "",
    })

    setSubFilters((prev) => {
      const updatedSubfilters = [...prev]
      updatedSubfilters[updatedSubfilters.length - 1].item =
        data?.[selectedCategory?.mainCategory as keyof typeof data]?.[
          selectedCategory?.parentCategory as keyof (typeof data)[keyof typeof data]
        ]
      return updatedSubfilters
    })
  }

  const getSubFiltersItems = () => {
    let filterItems: QueryFilterData[] = []
    if (selectedCategory?.mainCategory === "data") {
      const data = availableFilters?.data[
        selectedCategory?.parentCategory as DataFilterKeys
      ]?.items?.find((item) => item.id === selectedCategory.subcategoryId)
      filterItems = data?.items || []
    } else {
      filterItems =
        subFilters.length > 0
          ? subFilters[subFilters.length - 1]?.item?.items || []
          : subCategoryData
    }

    return (
      filterItems?.map((item) => ({
        ...item,
        type: selectedCategory?.parentCategory,
      })) || []
    )
  }

  const items = !selectedCategory?.subcategoryId
    ? getFilterData()
    : getSubFiltersItems()

  const subFilterName = subFilters?.[subFilters.length - 1]?.heading

  return (
    <div className={styles.productsSection}>
      <div
        className={clsx(styles.container, {
          [styles.scrollable]: !selectedCategory,
        })}
      >
        {selectedCategory ? (
          <CategoryList
            selectedCategory={selectedCategory}
            items={items}
            subFilterName={subFilterName}
            handleCategoryBack={handleCategoryBack}
            checkAndApplyFilter={checkAndApplyFilter}
            subFilterSelect={subFilterSelect}
            filtersToApply={filtersToApply}
            isPaginationLoading={isPaginationLoading}
            lastItemRef={lastItemRef}
            handleContentSelect={handleContentSelect}
          />
        ) : (
          <FilterList
            availableFilters={availableFilters}
            setSelectedCategory={setSelectedCategory}
            handleSubcategorySelect={handleSubcategorySelect}
          />
        )}
      </div>
    </div>
  )
})

export default ProductsSection
