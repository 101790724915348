import { makeAutoObservable } from "mobx"
import cloneDeep from "lodash/cloneDeep"

import defaultErrorHandler from "@store/utils/error-handlers"
import {
  SolutionCategory,
  SolutionDetails,
  SolutionEntity,
} from "@framework/types/solution"
import solutionsService from "@services/solutions.service"
import {
  GeFilterType,
  QueryFilterData,
  ProductFilterKeys,
  DataFilterKeys,
  QueryFilter,
} from "@framework/types/query-filter"
import avatarsService from "@services/avatars.service"
import { FailResponse, SuccessResponse } from "@framework/types/common"

import SolutionsStore from "./solutions.store"
import DefaultFilters from "./default-filters"

const SINGULAR = {
  products: "product",
  categories: "category",
  dataSource: "datasource",
  dataType: "datatype",
}

export class SolutionsController {
  solutionsStore: SolutionsStore

  constructor(injections: { solutionsStore: SolutionsStore }) {
    this.solutionsStore = injections.solutionsStore
    makeAutoObservable(this)
  }

  loadAvatarSolutions = async (avatarId: string) => {
    const store = this.solutionsStore
    try {
      store.isLoading = true
      store.errorMessage = ""

      const response = await solutionsService.getSolutions(avatarId)

      store.setSolutions(response.data.data?.productSolutions ?? [])
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "loading solutions")
      return store.errorMessage
    } finally {
      store.isLoading = false
    }
    return null
  }

  loadAllSolutions = async () => {
    const store = this.solutionsStore
    try {
      store.allSolutionsLoading = true
      store.errorMessage = ""

      const response = await solutionsService.getAllProductSolutions()

      store.setAllSolutions(response.data.data ?? [])
    } catch (e: any) {
      store.errorMessage = "Unexpected error"
    } finally {
      store.allSolutionsLoading = false
    }
  }

  setCategory = (category: SolutionCategory) => {
    this.solutionsStore.category = category
  }

  checkAndApplyFilter = (filter: QueryFilterData) => {
    const store = this.solutionsStore
    const found = store.filtersToApply.find(
      (curFilter) => curFilter.id === filter.id
    )
    if (found) {
      store.filtersToApply = store.filtersToApply.filter(
        (f) => f.id !== found.id
      )
    } else {
      store.filtersToApply = [...store.filtersToApply, filter]
    }
  }

  clearAllFilters = () => {
    this.solutionsStore.filtersToApply = []
  }

  removeFilter = (filter: QueryFilterData) => {
    this.solutionsStore.filtersToApply =
      this.solutionsStore.filtersToApply.filter((item) => item.id !== filter.id)
  }

  applyFilters = () => {
    this.solutionsStore.appliedFilters = this.solutionsStore.filtersToApply
  }

  resetFiltersToApply = () => {
    this.solutionsStore.filtersToApply = this.solutionsStore.appliedFilters
  }

  loadFilters = async () => {
    const store = this.solutionsStore
    try {
      store.isFiltersLoading = true
      store.errorMessage = ""

      const response = await solutionsService.getFilters(DefaultFilters)
      store.availableFilters = response.data ?? {}
      this.setCategoriesCount()
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "loading filters")
      return store.errorMessage
    } finally {
      store.isFiltersLoading = false
    }
    return null
  }

  abortController = new AbortController()

  searchFilters = async (search: string) => {
    const store = this.solutionsStore
    try {
      this.abortController.abort()
      this.abortController = new AbortController()
      store.searchLoading = true
      store.errorMessage = ""
      const body = cloneDeep(DefaultFilters)
      body.filters = body.filters.map((item) => ({ ...item, search }))
      store.searchedFilters = []
      const response = await solutionsService.getFilters(
        body,
        this.abortController.signal
      )
      Object.keys(response.data?.data).forEach((key) => {
        const object = response.data?.data[key as DataFilterKeys]
        store.searchedFilters = [
          ...store.searchedFilters,
          ...object.items.map((it) => ({ ...it, type: key })),
        ]
      })

      Object.keys(response.data?.product).forEach((key) => {
        const object = response.data?.product[key as ProductFilterKeys]
        store.searchedFilters = [
          ...store.searchedFilters,
          ...object.items.map((it) => ({ ...it, type: key })),
        ]
      })
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "searching filters")
      return store.errorMessage
    } finally {
      store.searchLoading = false
    }
    return null
  }

  setCategoriesCount = () => {
    const categories =
      this.solutionsStore?.availableFilters?.product?.categories

    if (!categories) return

    categories.totalCount = categories.items.length
    categories.noMoreData = true
  }

  loadMoreFiltersData = async (params: {
    category: string
    mainCategory: string
    page?: number
    pageSize?: number
    subCategoryId?: string
  }): Promise<QueryFilter | null> => {
    const store = this.solutionsStore
    const { category, mainCategory, page, pageSize, subCategoryId } = params

    const filterType: GeFilterType = {
      page,
      pageSize,
      type: (SINGULAR as any)?.[category],
    }
    if (subCategoryId) {
      filterType.filterId = subCategoryId
    }
    try {
      store.isPaginationLoading = true
      store.errorMessage = ""

      const response = await solutionsService.getFilters({
        filters: [filterType],
      })
      const mainCatKey = mainCategory as keyof typeof store.availableFilters
      let oldData: QueryFilterData[]
      const newData = (response.data?.[mainCatKey] as any)?.[category] ?? {}

      if (mainCategory === "product") {
        const productCategory =
          store.availableFilters?.product?.[category as ProductFilterKeys]
        oldData = productCategory?.items ?? []

        if (productCategory) {
          if (subCategoryId) {
            const categories =
              store.availableFilters?.product?.categories?.items ?? []
            const categoryIndex = categories.findIndex(
              (item) => item.id === subCategoryId
            )

            if (categoryIndex !== -1 && categories[categoryIndex]) {
              categories[categoryIndex].items = newData.items
            }
          } else {
            productCategory.count += pageSize || 0
            productCategory.page = filterType.page
            productCategory.noMoreData = !newData?.items?.length
            productCategory.items = [...oldData, ...newData.items]
          }
        }
      }
      if (mainCategory === "data") {
        if (subCategoryId) {
          const categories =
            store.availableFilters?.data?.[category as DataFilterKeys]?.items
          const categoryIndex =
            categories?.findIndex((item) => {
              return item.id === subCategoryId
            }) ?? -1

          if (categoryIndex !== -1 && categories?.[categoryIndex]) {
            categories[categoryIndex].items = newData.items
          }
        } else {
          const dataCategory =
            store.availableFilters?.data?.[category as DataFilterKeys]
          oldData = dataCategory?.items ?? []

          if (dataCategory) {
            dataCategory.items = [...oldData, ...newData.items]
            dataCategory.count += pageSize || 0
            dataCategory.page = filterType.page
            dataCategory.noMoreData = !newData?.items?.length
          }
        }
      }
      return response.data
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "loading filters")
    } finally {
      store.isPaginationLoading = false
    }
    return null
  }

  toggleRecentActivity() {
    const store = this.solutionsStore
    store.showRecentActivity = !store.showRecentActivity
  }

  createSolution = async (
    avatarId: string,
    data: SolutionDetails
  ): Promise<FailResponse | SuccessResponse<SolutionEntity>> => {
    const store = this.solutionsStore
    try {
      store.isLoading = true
      store.errorMessage = ""

      const response = await solutionsService.createProductSolution(data)

      const solutionId = response.data.data.id

      if (solutionId == null) throw new Error("Unexpected error")

      await avatarsService.editAvatar(avatarId, {
        productSolutionIdsToAdd: [solutionId],
      })

      return {
        status: "SUCCESS",
        data: response.data.data,
      }
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "creating solutions")
      return {
        status: "FAILED",
        message: store.errorMessage,
      }
    } finally {
      store.isLoading = false
    }
  }

  updateSolution = async (
    solutionId: string,
    data: Partial<SolutionDetails>
  ): Promise<FailResponse | SuccessResponse<SolutionEntity>> => {
    const store = this.solutionsStore
    try {
      store.isLoading = true
      store.errorMessage = ""

      const response = await solutionsService.updateProductSolution(
        solutionId,
        data
      )

      const solutionIndex = store.solutions?.findIndex(
        (it) => it.id === solutionId
      )

      if (solutionIndex != null && store.solutions) {
        const newSolutions = store.solutions
        newSolutions.splice(solutionIndex, 1, response.data.data)
        store.setSolutions(newSolutions)
      }

      return {
        status: "SUCCESS",
        data: response.data.data,
      }
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "updating solutions")
      return {
        status: "FAILED",
        message: store.errorMessage,
      }
    } finally {
      store.isLoading = false
    }
  }

  deleteSolution = async (solutionId: string) => {
    const store = this.solutionsStore
    try {
      store.isLoading = true
      store.errorMessage = ""

      await solutionsService.removeProductSolution(solutionId)
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "deleting solution")
      return store.errorMessage
    } finally {
      store.isLoading = false
    }
    return null
  }
}

export default SolutionsController
