import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import { capitalizeFirstLetter, removeHyphens } from "@utils/textUtils"

import styles from "./FiltersSection.module.sass"

const FiltersSection: React.FC = observer(() => {
  const { solutionsStore } = useStore()
  const { filtersToApply } = solutionsStore
  const { solutionsController } = useController()
  const { removeFilter, clearAllFilters } = solutionsController
  return (
    <div className={styles.filtersSection}>
      <div className={styles.header}>
        <Text variant="body2">Filters</Text>
        <Button size="tiny" variant="outlined" onClick={clearAllFilters}>
          Clear All
        </Button>
      </div>
      <div className={styles.scrollable}>
        {filtersToApply?.map((filter) => (
          <Button
            after={<Icon name="cross" />}
            size="small"
            key={filter.id}
            color="secondary"
            onClick={() => removeFilter(filter)}
          >
            <div>{capitalizeFirstLetter(removeHyphens(filter.name))}</div>
          </Button>
        ))}
      </div>
    </div>
  )
})

export default FiltersSection
