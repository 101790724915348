import React from "react"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"

import Header from "./components/Header"
import ProductsSection from "./components/ProductsSection"
import FiltersSection from "./components/FiltersSection"
import Footer from "./components/Footer"

import styles from "./SearchFilterModal.module.sass"

export type SearchFilterModalProps = {}

const SearchFilterModal: React.FC<SearchFilterModalProps> = () => {
  return (
    <BaseModal className={styles.root} containerClassName={styles.container}>
      <Header />
      <div className={styles.body}>
        <ProductsSection />
        <FiltersSection />
      </div>
      <Footer />
    </BaseModal>
  )
}

export default SearchFilterModal
