import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import {
  LOGIN_MS_SSO_CLIENT_ID,
  LOGIN_MS_SSO_REDIRECT_URI,
} from "@pages/upload/AddDataConnector/constants"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import useLoginSSO from "./useLoginSSO"

export const LoginMSSSOForm: React.FC<{ primary?: boolean }> = observer(
  ({ primary }) => {
    const theme = useAppConfig()
    const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

    const { authStore } = useStore()
    const { dataConnectorController } = useController()

    const { isSignInSSOLoading, isLoading } = authStore

    useLoginSSO()

    const handleLogin = () => {
      window.location.href = dataConnectorController.getMicrosoftOAuthCodeURL({
        client_id: LOGIN_MS_SSO_CLIENT_ID,
        redirect_uri: LOGIN_MS_SSO_REDIRECT_URI,
      })
    }

    const btnLabel = theme.authorization.login.SSO.buttonText

    return (
      <Button
        size={isTablet ? "big" : "large"}
        variant={primary ? "contained" : "outlined"}
        color="primary"
        disabled={isLoading}
        onClick={handleLogin}
        after={isSignInSSOLoading && <Loader />}
      >
        {btnLabel}
      </Button>
    )
  }
)

export default LoginMSSSOForm
