import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DataConnectorSourceName } from "@framework/types/upload"
import { IconName } from "@components/ui/Icon/IconName"
import { DocumentIconType } from "@framework/types/utils"
import Text from "@components/ui/Typography/Text"
import SelectOption from "@components/ui/Dropdown/SelectOption"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import { CreateContentSourceParams } from "@framework/types/content-manager"

import { ModalsTypes } from "../constants"

import styles from "./ContentManagerConfigFormModal.module.sass"

export type ContentManagerConfigFormModalProps = {
  sourceName: DataConnectorSourceName
  icon?: DocumentIconType | IconName
  label: string
}

const validationSchema = yup.object({
  name: yup.string().required("Name is required").trim().defined(),
  domain: yup.string().required("Domain is required").trim().defined(),
  clientId: yup.string().required("Client ID is required").trim().defined(),
  clientSecret: yup
    .string()
    .required("Client Secret is required")
    .trim()
    .defined(),
  contentType: yup.string().required("Content Type is required").defined(),
})

export type FormData = yup.InferType<typeof validationSchema>

const ContentManagerConfigFormModal: React.FC<ContentManagerConfigFormModalProps> =
  observer(({ sourceName, icon, label }) => {
    const initialValues = validationSchema.getDefault()
    const alert = useAlert()

    const { hideModal, hideAllModals } = useModal(
      ModalsTypes.CONTENT_MANAGER_CONFIG_FORM_MODAL
    )

    const { contentManagerController } = useController()
    const { contentManagerStore, allDatatypesStore } = useStore()
    const {
      datatypesController: { loadAllDataTypes },
    } = useController()

    const contentTypes = allDatatypesStore?.state?.data

    const { loadingCreatedContentSource } = contentManagerStore || {}
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (form) => {
        const success = await contentManagerController.createContentSource({
          ...form,
          type: sourceName,
        } as CreateContentSourceParams)
        if (success) {
          alert.success("Successfully created content source")
          hideAllModals()
        } else {
          alert.error("Failed to create")
        }
      },
    })

    useEffect(() => {
      loadAllDataTypes()
    }, [])

    return (
      <BaseModal
        className={styles.root}
        title={
          <ModalTitle
            titleText={
              <div className={styles.titleContainer}>
                {icon && <DocumentIcon icon={icon} />}
                {label}
              </div>
            }
          />
        }
        containerClassName={styles.container}
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button
                variant="outlined"
                size="medium"
                onClick={hideModal}
                disabled={loadingCreatedContentSource}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={loadingCreatedContentSource}
                size="medium"
                onClick={formik.submitForm}
              >
                Connect
              </Button>
            </div>
          }
        >
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.inputsWrapper}>
              <div className={styles.inputContainer}>
                <Text variant="body2">Connector Name</Text>
                <TextInput
                  name="name"
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={!!(formik.touched.name && formik.errors.name)}
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">Domain</Text>
                <TextInput
                  name="domain"
                  placeholder="Enter domain"
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={!!(formik.touched.domain && formik.errors.domain)}
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">Client ID</Text>
                <TextInput
                  name="clientId"
                  placeholder="Enter client ID"
                  value={formik.values.clientId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={
                    !!(formik.touched.clientId && formik.errors.clientId)
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">Client Secret</Text>
                <TextInput
                  name="clientSecret"
                  placeholder="Enter client secret"
                  value={formik.values.clientSecret}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={
                    !!(
                      formik.touched.clientSecret && formik.errors.clientSecret
                    )
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">Content Type</Text>
                <SelectOption
                  placement="top"
                  value={
                    contentTypes.find(
                      (contentType) =>
                        contentType.id === formik.values.contentType
                    )?.name
                  }
                  options={contentTypes?.map((item) => item.name)}
                  placeholder="Select content type"
                  onChange={(value) =>
                    formik.setFieldValue(
                      "contentType",
                      contentTypes.find(
                        (contentType) => contentType.name === value
                      )?.id
                    )
                  }
                  error={
                    formik.touched.contentType
                      ? formik.errors.contentType
                      : null
                  }
                />
              </div>
            </div>
          </form>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default ContentManagerConfigFormModal
