import { makeAutoObservable } from "mobx"
import uniq from "lodash/uniq"

import productsService from "@services/products.service"
import { CompanyTheme } from "@framework/types/company"
import { FailResponse, SuccessResponse } from "@framework/types/common"
import { CellValidationRule } from "@components/ui/Spreadsheet/types"
import {
  createMatrix,
  createMatrixFromSnapshot,
} from "@components/ui/Spreadsheet/createMatrix"
import solutionsService from "@services/solutions.service"

import UnifiedMatrixStore from "./unified-matrix.store"

export default class UnifiedMatrixController {
  matrix: UnifiedMatrixStore

  constructor(injections: { unifiedMatrixStore: UnifiedMatrixStore }) {
    this.matrix = injections.unifiedMatrixStore

    makeAutoObservable(this)
  }

  filesAbortController: AbortController | null = null

  fetchFiles = async (query: string): Promise<string[]> => {
    const store = this.matrix.filesStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await productsService.getTargetFiles(
        query,
        this.filesAbortController.signal
      )

      store.setData(response.data.data)

      return uniq(response.data.data.map((it) => it.name))
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  fetchProducts = async (query: string): Promise<string[]> => {
    const store = this.matrix.productsStore

    try {
      if (!this.filesAbortController?.signal.aborted)
        this.filesAbortController?.abort()

      this.filesAbortController = new AbortController()

      store.isLoading = true

      const response = await solutionsService.getFilters(
        {
          filters: [
            {
              type: "product",
              search: query,
              pageSize: 100,
            },
          ],
        },
        this.filesAbortController.signal
      )

      const items = response.data?.product?.products?.items ?? []

      store.setData(items)

      return items.map((it) => it.name)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return []
  }

  generateThemes = async (
    companyName: string,
    category: string,
    businessUnit?: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateThemes({
        companyName,
        category,
        businessUnit,
      })

      const themes = response.data.data

      return {
        status: "SUCCESS",
        data: themes.length ? makeThemesReport(themes) : "-",
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    }
  }

  generateAccountPlan = async (
    targetCompany: string,
    targetCompanyTheme: string,
    myCompanyTheme: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateAccountPlan({
        targetCompany,
        targetCompanyTheme,
        myCompanyTheme,
      })

      const accountPlan = response.data

      return {
        status: "SUCCESS",
        data: accountPlan,
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    }
  }

  generateDocumentComplianceReport = async (
    authorityDocument: string,
    regulatedDocument: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateDocumentComplianceReport({
        authorityDocument,
        regulatedDocument,
      })

      return {
        status: "SUCCESS",
        data: response.data,
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    }
  }

  generateProductPositioningReport = async (
    application: string,
    targetProduct: string,
    alternateProducts: string[]
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateProductPositioningReport({
        application,
        targetProduct,
        alternateProducts,
      })

      return {
        status: "SUCCESS",
        data: response.data,
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    }
  }
}

const makeThemesReport = (themes: CompanyTheme[]) => {
  return themes.map((it) => `- ${it.content}`).join("\n")
}
