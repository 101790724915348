export enum ModalsTypes {
  SUCCESS_MESSAGE_MODAL = "SUCCESS_MESSAGE_MODAL",
  LOGOUT_MODAL = "LOGOUT_MODAL",
  UPLOAD_FILES_MODAL = "UPLOAD_FILES_MODAL",
  ADD_EXPERTS_TO_QUESTIONS_MODAL = "ADD_EXPERTS_TO_QUESTIONS_MODAL",
  ASSIGN_EXPERTS_TO_QUESTIONS_MODAL = "ASSIGN_EXPERTS_TO_QUESTIONS_MODAL",

  CREATE_AVATAR_MODAL = "CREATE_AVATAR_MODAL",
  DELETE_AVATAR_MODAL = "DELETE_AVATAR_MODAL",
  EDIT_AVATAR_MEMBERSHIP_MODAL = "EDIT_AVATAR_MEMBERSHIP_MODAL",
  DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL = "DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL",

  EDIT_USER_GROUP_MEMBERSHIP_MODAL = "EDIT_USER_GROUP_MEMBERSHIP_MODAL",

  DATATYPE_CREATE_MODAL = "DATATYPE_CREATE_MODAL",
  DATATYPE_DELETE_MODAL = "DATATYPE_DELETE_MODAL",
  DATATYPE_ASSIGN_AVATAR_MODAL = "DATATYPE_ASSIGN_AVATAR_MODAL",

  APP_FEEDBACK_MODAL = "APP_FEEDBACK_MODAL",
  ANSWER_FEEDBACK_MODAL = "ANSWER_FEEDBACK_MODAL",
  REASSIGN_FILE_TO_DATA_TYPE_MODAL = "REASSIGN_FILE_TO_DATA_TYPE_MODAL",
  PRESELECT_DATA_TYPE_MODAL = "PRESELECT_DATA_TYPE_MODAL",
  SELECT_DATA_CONNECTOR_ACCOUNT_MODAL = "SELECT_DATA_CONNECTOR_ACCOUNT_MODAL",
  DATA_CONNECTOR_ACCOUNT_MODAL = "DATA_CONNECTOR_ACCOUNT_MODAL",
  CHOOSE_CONTENT_SOURCE_MODAL = "CHOOSE_CONTENT_SOURCE_MODAL",
  CONTENT_MANAGER_CONFIG_FORM_MODAL = "CONTENT_MANAGER_CONFIG_FORM_MODAL",
  CHANGE_USER_PASSWORD_MODAL = "CHANGE_USER_PASSWORD_MODAL",
  INVITE_USER_MODAL = "INVITE_USER_MODAL",

  CONFIRM_USER_DELETE_MODAL = "CONFIRM_USER_DELETE_MODAL",
  EDIT_USER_ROLE_MODAL = "EDIT_USER_ROLE_MODAL",
  EDIT_USER_ACTION_MODAL = "EDIT_USER_ACTION_MODAL",
  DELETE_USER_ROLE_MODAL = "DELETE_USER_ROLE_MODAL",
  DELETE_USER_ACTION_MODAL = "DELETE_USER_ACTION_MODAL",
  ASSIGN_USER_ROLES_MODAL = "ASSIGN_USER_ROLES_MODAL",
  EDIT_USER_GROUP_MODAL = "EDIT_USER_GROUP_MODAL",

  EDIT_FEATURE_FLAG_MODAL = "EDIT_FEATURE_FLAG_MODAL",
  DELETE_FEATURE_FLAG_MODAL = "DELETE_FEATURE_FLAG_MODAL",

  DELETE_SYNONYM_MODAL = "DELETE_SYNONYM_MODAL",
  DELETE_ACRONYM_MODAL = "DELETE_ACRONYM_MODAL",

  CREATE_INSTANCE_CONFIG_MODAL = "CREATE_INSTANCE_CONFIG_MODAL",
  DELETE_INSTANCE_CONFIG_MODAL = "DELETE_INSTANCE_CONFIG_MODAL",

  EDIT_EXPERT_TOPIC_MODAL = "EDIT_EXPERT_TOPIC_MODAL",
  EDIT_EXPERT_SUBTOPIC_MODAL = "EDIT_EXPERT_SUBTOPIC_MODAL",
  EDIT_EXPERT_ARTICLE_MODAL = "EDIT_EXPERT_ARTICLE_MODAL",

  CONFIRM_DELETE_MODAL = "CONFIRM_DELETE_MODAL",

  CREATE_API_KEY_MODAL = "CREATE_API_KEY_MODAL",
  DELETE_API_KEY_MODAL = "DELETE_API_KEY_MODAL",
  SHOW_API_KEY_MODAL = "SHOW_API_KEY_MODAL",

  CONFIRM_MODAL = "CONFIRM_MODAL",
  SUBSCRIPTION_UPGRADE_MODAL = "SUBSCRIPTION_UPGRADE_MODAL",

  PRODUCT_THEMES_MODAL = "PRODUCT_THEMES_MODAL",

  LONG_TEXT_MODAL = "LONG_TEXT_MODAL",

  SEARCH_FILTERS_MODAL = "SEARCH_FILTERS_MODAL",

  CONTENT_MANAGER_FILTERS_MODAL = "CONTENT_MANAGER_FILTERS_MODAL",
  SELECT_SOLUTION_TYPE_MODAL = "SELECT_SOLUTION_TYPE_MODAL",
  EDIT_SOLUTION_MODAL = "EDIT_SOLUTION_MODAL",

  ADD_COMMENT_MODAL = "ADD_COMMENT_MODAL",
}

export const HIDE_BLUR_MODALS = [ModalsTypes.CONTENT_MANAGER_FILTERS_MODAL]

export default {}
