import React from "react"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import Loader from "@components/ui/Loader/BarLoader"
import {
  camelCaseToWords,
  capitalizeFirstLetter,
  removeHyphens,
} from "@utils/textUtils"
import { CategoryInfo, QueryFilterData } from "@framework/types/query-filter"

import styles from "./ProductsSection.module.sass"

interface CategoryListProps {
  selectedCategory: CategoryInfo
  items: QueryFilterData[]
  handleCategoryBack: () => void
  checkAndApplyFilter: (filter: QueryFilterData) => void
  subFilterSelect: (filter: QueryFilterData, parent: string) => void
  filtersToApply: QueryFilterData[]
  isPaginationLoading: boolean
  lastItemRef: React.RefObject<HTMLDivElement>
  subFilterName?: string
  handleContentSelect: (filter: QueryFilterData, parentCategory: string) => void
}

const CategoryList: React.FC<CategoryListProps> = ({
  selectedCategory,
  items,
  handleCategoryBack,
  checkAndApplyFilter,
  subFilterSelect,
  filtersToApply,
  isPaginationLoading,
  lastItemRef,
  subFilterName,
  handleContentSelect,
}) => {
  const isData = selectedCategory?.name?.startsWith("data")
  return (
    <>
      <Text variant="body2" className={styles.filtersHeader}>
        <Button
          onClick={handleCategoryBack}
          variant="text"
          size="tiny"
          before={<Icon name="line-arrow-down" rotateAngle={90} />}
        >
          {capitalizeFirstLetter(
            camelCaseToWords(
              subFilterName ||
                selectedCategory?.name?.replace(/^data/, "content") ||
                ""
            )
          )}
        </Button>
      </Text>
      <div className={styles.scrollable}>
        <div className={clsx(styles.categories, styles.loaderList)}>
          {items.map((filter, index) => (
            <div
              className={styles.checkboxWrapper}
              ref={index === items.length - 1 ? lastItemRef : null}
              key={filter.id || filter.name}
            >
              <CheckboxWithLabel
                key={filter.id}
                className={clsx(styles.checkboxFilterName)}
                onCheck={() => checkAndApplyFilter(filter)}
                label={
                  <Text variant="body2" className={styles.name}>
                    {capitalizeFirstLetter(removeHyphens(filter.name))}
                    &nbsp;
                    {filter.hasChildren && (
                      <Text variant="body2" className={styles.count}>
                        ({filter?.count})
                      </Text>
                    )}
                  </Text>
                }
                checked={
                  !!filtersToApply.find(
                    (curFilter) => curFilter.id === filter.id
                  )
                }
              />
              {!!filter?.hasChildren && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() =>
                    isData
                      ? handleContentSelect(filter, selectedCategory?.name)
                      : subFilterSelect(
                          filter,
                          selectedCategory?.parentCategory || ""
                        )
                  }
                  after={<Icon name="line-arrow-down" rotateAngle={-90} />}
                />
              )}
            </div>
          ))}
          {isPaginationLoading && (
            <div className={styles.loaderWrapper}>
              <Loader size="small" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CategoryList
