import React from "react"

const NeshLogo: React.FC<{
  height?: number
  width?: number
  className?: string
}> = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3333 0.416667C21.6767 0.416667 23.5833 2.32333 23.5833 4.66667V19.3333C23.5833 21.6767 21.6767 23.5833 19.3333 23.5833H4.66667C2.32333 23.5833 0.416667 21.6767 0.416667 19.3333V4.66667C0.416667 2.32333 2.32333 0.416667 4.66667 0.416667H19.3333ZM19.3333 0H4.66667C2.08917 0 0 2.08917 0 4.66667V19.3333C0 21.9108 2.08917 24 4.66667 24H19.3333C21.9108 24 24 21.9108 24 19.3333V4.66667C24 2.08917 21.9108 0 19.3333 0Z"
      fill="white"
    />
    <path
      d="M6.93359 7.01953H10.5336L14.8336 17.1195C14.8669 17.2029 14.9336 17.2862 15.0503 17.2529C15.2169 17.2029 15.2003 17.0695 15.1503 16.9362C14.9836 16.4862 14.8003 16.0862 14.8003 15.5529V7.01953H17.0669V18.7529H13.4669L9.16693 8.65287C9.13359 8.56953 9.06693 8.4862 8.95026 8.51953C8.78359 8.56953 8.80026 8.70286 8.85026 8.8362C9.01693 9.2862 9.20026 9.6862 9.20026 10.2195V18.7529H6.93359V7.01953Z"
      fill="white"
    />
    <path
      d="M17.9062 3.30854C17.9863 3.30854 18.0637 3.30188 18.1396 3.28854C18.2154 3.27521 18.2837 3.25271 18.3446 3.22187C18.4054 3.19104 18.4579 3.15104 18.5013 3.10187C18.5446 3.05271 18.5729 2.99271 18.5862 2.92188H18.8962V5.25521H18.4796V3.60854H17.9062V3.30854Z"
      fill="white"
    />
    <path
      d="M20.3429 5.30167C20.2254 5.30167 20.1154 5.285 20.0145 5.25167C19.9137 5.21833 19.8262 5.17 19.7529 5.10667C19.6795 5.04333 19.622 4.96583 19.5812 4.875C19.5404 4.78417 19.5195 4.68167 19.5195 4.56833C19.5195 4.42167 19.5579 4.29833 19.6345 4.19833C19.7112 4.09833 19.8154 4.03167 19.9462 3.99833V3.99167C19.8395 3.95167 19.7579 3.89 19.7012 3.80667C19.6445 3.72333 19.6162 3.625 19.6162 3.51167C19.6162 3.31167 19.6787 3.15583 19.8045 3.04333C19.9304 2.93083 20.1095 2.875 20.3429 2.875C20.5762 2.875 20.7554 2.93083 20.8812 3.04333C21.007 3.15583 21.0695 3.31167 21.0695 3.51167C21.0695 3.625 21.0412 3.72333 20.9845 3.80667C20.9279 3.89 20.8462 3.95167 20.7395 3.99167V3.99833C20.8704 4.03167 20.9745 4.09833 21.0512 4.19833C21.1279 4.29833 21.1662 4.42167 21.1662 4.56833C21.1662 4.68167 21.1454 4.78417 21.1045 4.875C21.0637 4.96583 21.0062 5.04333 20.9329 5.10667C20.8595 5.17 20.772 5.21833 20.6712 5.25167C20.5704 5.285 20.4604 5.30167 20.3429 5.30167ZM20.3429 5.00167C20.4695 5.00167 20.5754 4.96417 20.6595 4.88833C20.7437 4.8125 20.7862 4.70583 20.7862 4.56833C20.7862 4.43917 20.7437 4.33917 20.6595 4.26667C20.5754 4.19417 20.4695 4.15833 20.3429 4.15833C20.2162 4.15833 20.1104 4.19417 20.0262 4.26667C19.942 4.33917 19.8995 4.43917 19.8995 4.56833C19.8995 4.70583 19.942 4.8125 20.0262 4.88833C20.1104 4.96417 20.2162 5.00167 20.3429 5.00167ZM20.3429 3.175C20.2404 3.175 20.1537 3.20583 20.0829 3.26667C20.012 3.3275 19.9762 3.41583 19.9762 3.53167C19.9762 3.6475 20.0104 3.7275 20.0795 3.78667C20.1487 3.84583 20.2362 3.875 20.3429 3.875C20.4495 3.875 20.537 3.84583 20.6062 3.78667C20.6754 3.7275 20.7095 3.6425 20.7095 3.53167C20.7095 3.42083 20.6737 3.3275 20.6029 3.26667C20.532 3.20583 20.4454 3.175 20.3429 3.175Z"
      fill="white"
    />
  </svg>
)

export default NeshLogo
