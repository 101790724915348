import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import Pagination from "@components/ui/Pagination/Pagination"
import usePagination from "@components/ui/Pagination/usePagination"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import Text from "@components/ui/Typography/Text"
import Skeleton from "@components/ui/Skeleton/Skeleton"

import ContentRow from "./components/ContentRow/ContentRow"
import SyncInfo from "./components/SyncInfo/SyncInfo"

import styles from "./ContentManager.module.sass"

export const ContentManagerPage: React.FC = observer(() => {
  const navigate = useNavigate()
  const filtersModal = useModal(ModalsTypes.CONTENT_MANAGER_FILTERS_MODAL)
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)
  const { contentManagerController } = useController()
  const { contentManagerStore } = useStore()
  const {
    documentsList,
    loadingDocumentsList,
    documentsMeta,
    filters,
    loadingSyncJobs,
    getAvailableFilterOptions,
  } = contentManagerStore

  const filtersCount = Object.values(filters).reduce(
    (acc, filterArray) => acc + filterArray.length,
    0
  )

  const paginationProps = usePagination({
    pageSize: documentsMeta?.pageSize || 0,
    totalRows: documentsMeta?.total || 0,
    totalPages: documentsMeta?.totalPages || 0,
  })

  const chooseContentSourceModal = useModal(
    ModalsTypes.CHOOSE_CONTENT_SOURCE_MODAL
  )

  const handleConnect = () => {
    chooseContentSourceModal.showModal({})
  }

  const showFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonElement = event.currentTarget
    const rect = buttonElement.getBoundingClientRect()
    const top = rect.top + 48 // 48px is the height + gap for filter button
    const left = rect.left + rect.width
    filtersModal.showModal({
      top,
      left,
      filtersList: getAvailableFilterOptions(),
    })
  }

  useEffect(() => {
    contentManagerController.loadDocumentsList({
      pageSize: 10,
      pageNum: paginationProps.forcePage,
    })
  }, [paginationProps.forcePage])

  useEffect(() => {
    contentManagerController.loadContentTypes()
    contentManagerController.loadContentSources()
    contentManagerController.loadSyncJobs()
  }, [])

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton size="small" onClick={() => navigate("../")}>
                Content Manager
              </BackButton>
            }
            right={
              <Button color="primary" size="big" onClick={handleConnect}>
                Add Content
              </Button>
            }
          >
            <TextInput
              before={<Icon name="search" />}
              placeholder="Search"
              onChange={() => {}}
            />
          </EntityPageHeaderLayout>
          {loadingSyncJobs && <Skeleton className={styles.syncSkeleton} />}

          {!!contentManagerStore?.syncJobs?.length && (
            <SyncInfo jobs={contentManagerStore.syncJobs} />
          )}
          {/* hidden until API integration */}
          {/* <div className={styles.filterContainer}>
            <div>
              {!!filtersCount && (
                <Button
                  size="medium"
                  before={<Icon name="cross" />}
                  color="secondary"
                  className={styles.deleteChip}
                  onClick={contentManagerStore.clearFilters}
                >
                  {filtersCount} Selected
                  <div className={styles.selectedFilterDelete}>
                    <Icon name="storage" />
                    <Icon name="trash-can" color="red" />
                  </div>
                </Button>
              )}
            </div>
            <Button
              color="secondary"
              size="medium"
              before={<Icon name="funnel" />}
              onClick={(e) => showFilters(e)}
            >
              {!isMobile && "Filters"}
            </Button>
          </div> */}
          {!loadingDocumentsList && !documentsList.length && (
            <NotFound>
              <Text variant="h2">No documents found</Text>
            </NotFound>
          )}
          <LoadingCover
            isLoading={loadingDocumentsList}
            className={styles.body}
          >
            {documentsList?.map((item) => (
              <ContentRow key={item.id} data={item} />
            ))}
          </LoadingCover>
          {!!documentsList?.length && (
            <EntityPageHeaderLayout
              className={styles.footer}
              right={<Pagination {...paginationProps} />}
            />
          )}
        </div>
      </Container>
    </MainLayout>
  )
})

export default ContentManagerPage
