import { makeAutoObservable, when } from "mobx"
import cloneDeep from "lodash/cloneDeep"

import { filterByQuery, findByQuery } from "@utils/textUtils"

import { Parser, tokenize } from "../parser"
import EditManager from "./EditManager"
import FormulaExecutor from "./FormulaExecutor"
import CellValidationManager from "./CellValidationManager"
import { CellComment, CellFormat, CellSnapshot, CellState } from "../types"

const formulaParser = new Parser()

const defaultState: CellState = {
  input: "",
  value: "",
  error: null,
  formula: null,
  comment: null,
  isLoading: false,
}

class CellManager {
  // injections

  editManager: EditManager

  validationManager: CellValidationManager

  // state

  id: string

  state: CellState

  validationRuleId: string | null

  task: FormulaExecutor | null = null

  subscriptions: Set<string> = new Set()

  constructor(options: {
    id: string
    manager: EditManager
    validationManager: CellValidationManager
    initialState?: CellState
    initialValidationRuleId?: string | null
  }) {
    this.id = options.id

    this.editManager = options.manager
    this.validationManager = options.validationManager

    this.validationRuleId = options.initialValidationRuleId ?? null

    this.state = {
      ...defaultState,
      ...(options.initialState ?? null),
    }

    makeAutoObservable(this)
  }

  get format() {
    return this.state.format
  }

  get value() {
    return this.state.value
  }

  get isLoading() {
    return this.state.isLoading
  }

  get isComputable() {
    return this.state.formula != null
  }

  get validationRule() {
    if (this.validationRuleId == null) return null
    return this.validationManager.getRuleByName(this.validationRuleId)
  }

  init = () => {
    if (this.state.isLoading) {
      this.apply()
      return
    }

    if (this.state.formula?.refs) {
      this.editManager.subscribeRefs(this.id, this.state.formula.refs)
    }
  }

  getFinalValue = async () => {
    await when(() => !this.isLoading)
    return this.value
  }

  apply = async () => {
    const { input, value: prevValue } = this.state

    try {
      this.updateState({
        isLoading: true,
        formula: null,
        value: null,
        error: null,
      })

      this.unsubscribeFormulaRefs()

      this.task?.cancel()
      this.task = null

      const validState = this.processValidation(input)

      if (validState != null) {
        this.updateState({ ...validState, isLoading: false })
        return
      }

      const newState = await this.processFormula(input)

      if (newState == null) return

      this.updateState({ ...newState, isLoading: false })
    } catch (error: any) {
      this.updateState({
        isLoading: false,
        error:
          typeof error.message === "string"
            ? error.message
            : "Unexpected error",
      })
    } finally {
      // eslint-disable-next-line eqeqeq
      if (prevValue != this.state.value) {
        this.notifyAndUnsubscribe(this.editManager.notifyValueUpdated)
      }
    }
  }

  unsubscribeFormulaRefs = () => {
    if (!this.state.formula?.isValid) return
    if (!this.state.formula.refs.length) return

    this.editManager.unsubscribeRefs(this.id, this.state.formula.refs)
  }

  processValidation = (inputValue: string): Partial<CellState> | null => {
    const { validationRule } = this

    if (!inputValue) return null

    if (validationRule == null) {
      if (this.validationRuleId) this.validationRuleId = null
      return null
    }

    if (validationRule.type === "OPTION" && validationRule.list.length) {
      const options = filterByQuery(validationRule.list, inputValue)

      if (options.length === 1) {
        const onlyOption = options[0]

        return {
          value: onlyOption,
          input: onlyOption,
        }
      }

      const exactMatch = findByQuery(options, inputValue)

      const newValue = exactMatch || ""
      return {
        value: newValue,
        input: newValue,
      }
    }

    if (validationRule.type === "AUTOCOMPLETE") {
      return {
        value: inputValue,
        input: inputValue,
      }
    }

    return null
  }

  processFormula = async (
    inputValue: string
  ): Promise<Partial<CellState> | null> => {
    if (!inputValue?.startsWith("=")) {
      return {
        input: inputValue,
        value: inputValue,
      }
    }

    const tokens = tokenize(inputValue.substring(1))

    const formula = formulaParser.parse(tokens)

    this.state.formula = formula

    this.editManager.validateRefs(this.id, formula.refs)

    const formulaTask = new FormulaExecutor({
      cellID: this.id,
      manager: this.editManager,
      formula,
    })

    this.task = formulaTask

    await formulaTask.run()

    this.task = null

    if (formulaTask.status === "completed") {
      return {
        value: formulaTask.result,
        formula,
      }
    }

    if (formulaTask.status === "failed") {
      return {
        error: formulaTask.error,
        formula,
      }
    }

    return null
  }

  updateState = (newState: Partial<CellState> = {}) => {
    this.state = {
      ...this.state,
      ...newState,
    }
  }

  setInput = (newInput: string = "") => {
    const input = newInput.trim()

    if (this.state.input === input) return

    this.state = {
      ...this.state,
      input,
      isLoading: true,
      formula: null,
    }
  }

  setValidationRule = (validation: string | null) => {
    this.validationRuleId = validation
  }

  setComment = (comment: CellComment | null) => {
    this.state.comment = comment
  }

  serialize = (): CellSnapshot => {
    return {
      state: cloneDeep(this.state),
      validationRuleId: this.validationRuleId,
    }
  }

  cleanUp = () => {
    this.setInput("")
    this.apply()
  }

  reset = () => {
    this.state = {
      ...defaultState,
    }
    this.validationRuleId = null
  }

  subscribe = (cellId: string) => {
    this.subscriptions.add(cellId)
  }

  unsubscribe = (cellId: string) => {
    this.subscriptions.delete(cellId)
  }

  notifyAndUnsubscribe = (callback: (cellId: string) => void) => {
    this.subscriptions.forEach((cellId) => callback(cellId))
    // this.subscriptions = new Set()
  }

  applyFormatting = (format: CellFormat) => {
    this.state.format = { ...this.state.format, ...format }
  }

  resetFormatting = () => {
    this.state.format = {}
  }
}

export default CellManager
