import keyBy from "lodash/keyBy"
import filter from "lodash/filter"
import values from "lodash/values"

import EditManager from "./EditManager"
import { FunctionDescription } from "../types"
import DEFAULT_FUNCTION_LIST from "./default-functions"

class FunctionManager {
  // injections

  manager: EditManager

  // state

  functions: Record<string, FunctionDescription>

  constructor(options: { manager: EditManager }) {
    this.manager = options.manager

    this.functions = keyBy(DEFAULT_FUNCTION_LIST, (it) => it.name.toUpperCase())
  }

  getFunction = (name: string) => {
    const func = this.functions[name.toUpperCase()]

    if (func == null) {
      throw new Error(`Unknown function name: '${name}'`)
    }

    return func
  }

  hasFunction = (name: string) => {
    return this.functions[name.toUpperCase()] != null
  }

  registerFunction = (newFunc: FunctionDescription) => {
    const name = newFunc.name.toUpperCase()

    if (this.hasFunction(name)) return false

    this.functions[name] = { ...newFunc, name }

    return true
  }

  findFunctions = (query: string): FunctionDescription[] => {
    const upQuery = query.trim().toUpperCase()

    const allFunctions = values(this.functions)

    if (!upQuery) return allFunctions

    return filter(allFunctions, (it) => it.name.includes(upQuery))
  }
}

export default FunctionManager
