import React from "react"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"

import NeshLogo from "./NeshLogo"
import IEEELogo from "./IEEELogo"
import DKSHLogo from "./DKSHLogo"
import ColorconLogo from "./ColorconLogo"

import styles from "./Logo.module.sass"

export interface LogoProps extends React.HTMLProps<HTMLElement> {
  name?: string // "NESH" | "ASKIEEE" | DKSH | COLORCON | other
  height?: number
  width?: number
  withText?: boolean
  primary?: boolean
}

export const Logo: React.FC<LogoProps> = React.memo(
  ({ name = "NESH", height, width, withText = false, primary = false }) => {
    switch (name.toUpperCase()) {
      case "ASKIEEE":
        return (
          <span className={styles.iconContainer}>
            <IEEELogo
              height={height}
              width={width}
              className={clsx(styles.ieeeIcon, { [styles.primary]: primary })}
            />
          </span>
        )

      case "DKSH":
        return (
          <span className={styles.iconContainer}>
            <DKSHLogo
              height={height}
              width={width}
              className={clsx(styles.ieeeIcon, { [styles.primary]: primary })}
            />
          </span>
        )

      case "COLORCON":
        return (
          <span className={styles.iconContainer}>
            <ColorconLogo
              height={height}
              width={width}
              className={clsx(styles.ieeeIcon, { [styles.primary]: primary })}
            />
          </span>
        )

      default:
        return (
          <span className={styles.iconContainer}>
            <NeshLogo
              height={height}
              width={width}
              className={clsx(styles.neshIcon, { [styles.primary]: primary })}
            />
            {!!withText && (
              <Text variant="h4" color="inherit">
                Nesh
              </Text>
            )}
          </span>
        )
    }
  }
)

export default Logo
