import React from "react"
import { Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { useController, useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import NotFound from "@components/ui/NotFound/NotFound"
import { WorkFlowType } from "@framework/types/solution"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import mainRoutes from "@root/main.routes"
import { AppMediaQueries } from "@framework/constants/app"
import useMediaQuery from "@components/hooks/useMediaQuery"

import FactFinderPage from "./SolutionTabsContainer/components/Tabs/FactFinder"
import ProductComparison from "./SolutionTabsContainer/components/Tabs/ProductComparison"
import UnifiedMatrix from "./SolutionTabsContainer/components/Tabs/UnifiedMatrix"

import styles from "./LaunchSolutions.module.sass"

const LaunchSolutions: React.FC = observer(() => {
  const { solutionType } = useParams()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  const buttonSize = isMobile ? "tiny" : isTablet ? "small" : "medium"

  if (solutionType == null) throw new Error("'solutionType' is required param")

  const {
    restrictionsStore: access,
    factFinderSolutionStore: { searchFlowStore },
    solutionsStore,
  } = useStore()

  const {
    appConfigController: { loadAllInstanceConfigurations },
  } = useController()

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    })
  }, [])

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })
  }, [searchFlowStore.flowSequence.length])

  React.useEffect(() => {
    loadAllInstanceConfigurations()
  }, [])

  const activeSolution = solutionsStore.solution

  const renderSolution = () => {
    if (!activeSolution) return <></>
    switch (solutionType) {
      case WorkFlowType.SEARCH:
        return <FactFinderPage data={activeSolution} />

      case WorkFlowType.WORKBOOK:
        return <UnifiedMatrix solution={activeSolution} />

      case WorkFlowType.PRODUCT_COMPARISON:
        return (
          <Routes>
            <Route
              path="/"
              element={<ProductComparison data={activeSolution} />}
            >
              <Route
                path=":step"
                element={<ProductComparison data={activeSolution} />}
              />
            </Route>
          </Routes>
        )

      default:
        return <NotFound>Solution is not available</NotFound>
    }
  }

  const handleBackClick = () => {
    navigate(mainRoutes.home())
  }

  const handleEditWorkbookPreset = (solutionId: string) => {
    navigate(
      `${mainRoutes.solutionEdit(
        WorkFlowType.WORKBOOK,
        solutionId
      )}?session=reload`
    )
  }

  const handleRecentActivity = () => {
    solutionsStore.showRecentActivity = true
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleReset = () => {
    searchFlowStore.reset()
    searchFlowStore.isFlowReset = true
  }

  const renderHeaderOptions = () => {
    if (!activeSolution) return <></>
    switch (solutionType) {
      case WorkFlowType.SEARCH: {
        return (
          <>
            {!!searchFlowStore?.flowSequence?.length && (
              <Button
                size={buttonSize}
                color="primary"
                disabled={searchFlowStore?.isAnyLoading()}
                onClick={handleReset}
                className={styles.newChatButton}
                before={<Icon name="plus" />}
              >
                {!isMobile && "Start new Chat"}
              </Button>
            )}
            <Button
              size={buttonSize}
              color={isMobile ? "default" : "secondary"}
              variant={isMobile ? "text" : "contained"}
              onClick={handleRecentActivity}
              before={<Icon name="retry" />}
            >
              {!isMobile && "Recent Activity"}
            </Button>
          </>
        )
      }

      case WorkFlowType.WORKBOOK: {
        return (
          <>
            {!!access.canUpdateSolution && (
              <Button
                size={buttonSize}
                color={isMobile ? "default" : "secondary"}
                variant={isMobile ? "text" : "contained"}
                onClick={() => handleEditWorkbookPreset(activeSolution.id)}
                before={<Icon name="standards" />}
              >
                Edit Preset
              </Button>
            )}
          </>
        )
      }

      default:
        return null
    }
  }

  return (
    <MainLayout className={styles.mainLayout} noPadding>
      <div className={clsx(styles.root)}>
        <Templates.Header
          className={styles.header}
          left={
            <Button
              before={<Icon name="line-arrow-down" rotateAngle={90} />}
              onClick={handleBackClick}
              variant="text"
              size={buttonSize}
            >
              {activeSolution?.name}
            </Button>
          }
          right={renderHeaderOptions()}
        />

        <div className={styles.body}>{renderSolution()}</div>
        <Outlet />
      </div>
    </MainLayout>
  )
})

export default LaunchSolutions
