import React, { ReactNode } from "react"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"

import styles from "./ContentInfo.module.sass"

export type ContentInfoProps = {
  title: string
  icon: ReactNode
  info: ReactNode
  isStriped: boolean
}
const ContentInfo: React.FC<ContentInfoProps> = ({
  title,
  info,
  icon,
  isStriped,
}) => {
  return (
    <div className={clsx(styles.root, { [styles.striped]: isStriped })}>
      <div className={styles.title}>
        <Text variant="body1">{title}</Text>
        <Icon name="arrow-up" rotateAngle={90} />
      </div>
      <div className={styles.info}>
        {icon}
        <Text variant="body1">{info}</Text>
      </div>
    </div>
  )
}

export default ContentInfo
