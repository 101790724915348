import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import { DataSourceNodeName } from "@framework/types/upload"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { renderShortNumber } from "@utils/numberUtils"
import Avatar from "@components/ui/Avatar/Avatar"
import { KibanaConnector } from "@framework/types/content-manager"
import { DocumentIconType } from "@framework/types/utils"
import { IconName } from "@components/ui/Icon/IconName"

import styles from "./DataConnectorAccountItem.module.sass"

export interface DataConnectorAccountItemProps {
  data: KibanaConnector
  control?: React.ReactNode
  onClick?: React.MouseEventHandler
  icon?: DocumentIconType | IconName
}

export const DataConnectorAccountItem: React.FC<DataConnectorAccountItemProps> =
  observer(({ data, control, onClick, icon }) => {
    const connectorContext = getDataSourceNodeContextProps(
      data.type as DataSourceNodeName
    )

    const totalFiles = data.contentCount ?? 0

    return (
      <button type="button" className={styles.root} onClick={onClick}>
        <div className={styles.header}>
          <span>
            <DocumentIcon
              icon={icon || connectorContext.icon}
              width={27}
              height={27}
            />
            <Text variant="h3">{data.name}</Text>
          </span>
          <Chip
            title={`Content: ${totalFiles} Files`}
            color="secondary"
            variant="rounded"
          >
            Contents: {renderShortNumber(totalFiles)}
          </Chip>
        </div>
        <div className={styles.info}>
          <span>Domain:</span>
          <Text variant="body2">{data.domain}</Text>
        </div>
        <div className={styles.info}>
          <span>Last Sync Date:</span>
          <Text variant="body2">{moment(data.lastSyncDate).fromNow()}</Text>
        </div>
        <div className={styles.info}>
          <span>Connected By:</span>
          <div className={styles.userAvatar}>
            <Avatar
              height={24}
              width={24}
              name={data?.connectedBy?.name || ""}
            />
            {`${data?.connectedBy?.name || ""}`}
          </div>
        </div>
        <Chip
          title={`Content: ${totalFiles} Files`}
          color="secondary"
          variant="rounded"
          className={styles.mobileUser}
        >
          Contents: {renderShortNumber(totalFiles)}
        </Chip>
      </button>
    )
  })

export default DataConnectorAccountItem
