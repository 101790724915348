import { makeAutoObservable } from "mobx"

import ContentManagerAPI from "@services/content-manager.service"
import solutionsService from "@services/solutions.service"
import { GeFilterType } from "@framework/types/query-filter"
import RootStore from "@store/RootStore"
import {
  CreateContentSourceParams,
  GetConnectorDocumentsParams,
} from "@framework/types/content-manager"

import ContentManagerStore from "./content-manager.store"

export default class ContentManagerController {
  contentManagerStore: ContentManagerStore

  constructor(injections: RootStore) {
    this.contentManagerStore = injections.contentManagerStore

    makeAutoObservable(this)
  }

  getContentSourceCountsByType = (type: string) => {
    const data = this.contentManagerStore.kibanaConnectorsCount?.find(
      (item) => item.type === type
    )
    return `${data?.accountCount || 0} Accounts | ${
      data?.documentCount || 0
    } contents`
  }

  loadKibanaConnectorAccounts = async (type: string) => {
    const store = this.contentManagerStore
    store.loadingKibanaConnectorAccounts = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getAvailableContentSources(type)
      store.kibanaConnectorAccounts = response.data.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingKibanaConnectorAccounts = false
    }
  }

  loadKibanaConnectorsCount = async () => {
    const store = this.contentManagerStore
    store.loadingKibanaConnectorsCount = true
    store.error = null
    store.kibanaConnectorAccounts = []
    try {
      const response = await ContentManagerAPI.getKibanaConnectorsCount()
      store.kibanaConnectorsCount = response.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingKibanaConnectorsCount = false
    }
  }

  createContentSource = async (
    data: CreateContentSourceParams
  ): Promise<boolean> => {
    const store = this.contentManagerStore
    store.loadingCreatedContentSource = true
    store.error = null
    try {
      const response = await ContentManagerAPI.createContentSource(data)
      store.createdContentSource = response.data
      return true
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingCreatedContentSource = false
    }
    return false
  }

  loadDocumentsList = async (params: GetConnectorDocumentsParams) => {
    const store = this.contentManagerStore
    store.loadingDocumentsList = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getDocumentsList(params)
      store.documentsList = response.data.data
      store.documentsMeta = response.data.meta
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingDocumentsList = false
    }
  }

  loadContentTypes = async () => {
    const store = this.contentManagerStore
    const filterType: GeFilterType = {
      page: 1,
      pageSize: 1000,
      type: "datatype",
    }
    const response = await solutionsService.getFilters({
      filters: [filterType],
    })

    store.contentTypes = Array.isArray(response?.data?.data?.dataType?.items)
      ? response.data.data.dataType.items
      : []
  }

  loadContentSources = async () => {
    const store = this.contentManagerStore
    const filterType: GeFilterType = {
      page: 1,
      pageSize: 1000,
      type: "datasource",
    }
    const response = await solutionsService.getFilters({
      filters: [filterType],
    })

    store.contentSources = Array.isArray(
      response?.data?.data?.dataSource?.items
    )
      ? response.data.data.dataSource.items
      : []
  }

  loadSyncJobs = async () => {
    const store = this.contentManagerStore
    store.loadingSyncJobs = true
    store.error = null
    try {
      const response = await ContentManagerAPI.getSyncJobs()
      store.syncJobs = response.data.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingSyncJobs = false
    }
  }

  refresh = () => {}
}
