import { AxiosResponse } from "axios"

import {
  CreateContentSourceParams,
  CreateContentSourceResponse,
  DocumentsListResponse,
  GetConnectorDocumentsParams,
  GetKibanaConnectorAccountsResponse,
  GetSyncJobsResponse,
  KibanaConnectorInfo,
} from "@framework/types/content-manager"

import HttpService from "./http.service"

class ContentManagerAPI extends HttpService {
  getAvailableContentSources = (
    type: string
  ): Promise<AxiosResponse<GetKibanaConnectorAccountsResponse>> =>
    this.get("ts/kibana-connectors", true, { type, pageSize: 1000, pageNum: 1 })

  getKibanaConnectorsCount = (): Promise<
    AxiosResponse<KibanaConnectorInfo[]>
  > => this.get(`ts/kibana-connectors/counts`, true)

  createContentSource = (
    data: CreateContentSourceParams
  ): Promise<AxiosResponse<CreateContentSourceResponse>> =>
    this.post("ts/kibana-connectors", data, true)

  getDocumentsList = (
    params: GetConnectorDocumentsParams
  ): Promise<AxiosResponse<DocumentsListResponse>> =>
    this.get("ts/kibana-connectors/documents", true, params)

  getSyncJobs = (): Promise<AxiosResponse<GetSyncJobsResponse>> =>
    this.get("ts/kibana-connectors/sync-jobs", true, {
      pageSize: 10,
      pageNum: 1,
      status: "in_progress",
    })
}

export {}

export default new ContentManagerAPI()
