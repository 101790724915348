import React from "react"

import Button from "@components/ui/Button/Button"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store"

import styles from "./Footer.module.sass"

const Footer: React.FC = () => {
  const { hideModal } = useModal(ModalsTypes.SEARCH_FILTERS_MODAL)
  const { solutionsController } = useController()
  const searchModal = useModal(ModalsTypes.CONFIRM_MODAL)
  const {
    factFinderSolutionStore: { searchFlowStore },
  } = useStore()

  const applyFilter = () => {
    solutionsController.applyFilters()
    hideModal()
  }

  const handleApplyClick = () => {
    if (searchFlowStore?.flowSequence?.length) {
      searchModal.showModal({
        message: (
          <>
            Modifying filters will reset your current conversation.
            <br />
            Are you sure you want to proceed?
          </>
        ),
        confirm: "positive",
        onConfirm: handleConfirm,
        confirmButtonText: "Proceed",
      })
    } else {
      applyFilter()
    }
  }

  const handleConfirm = () => {
    applyFilter()
  }

  return (
    <div className={styles.footer}>
      <Button onClick={hideModal} size="medium" variant="outlined">
        Cancel
      </Button>
      <Button
        onClick={handleApplyClick}
        size="medium"
        variant="contained"
        color="primary"
      >
        Apply
      </Button>
    </div>
  )
}

export default Footer
