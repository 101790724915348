import React from "react"

import Box from "@components/ui/Dropdown/Box"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import styles from "./Tooltip.module.sass"

export const ErrorTooltip: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  return (
    <Box color="primary" className={styles.root}>
      <Text color="redColor" variant={isTablet ? "h4" : "h5"}>
        <Icon name="alert" inline /> Error
      </Text>
      <Text variant="body2" className={styles.message}>
        {children}
      </Text>
    </Box>
  )
}

export default ErrorTooltip
