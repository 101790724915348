import React, { ReactNode, useMemo, useState } from "react"
import moment from "moment"
import ReactJson from "react-json-view"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Chip from "@components/ui/Chip/Chip"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Avatar from "@components/ui/Avatar/Avatar"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import Text from "@components/ui/Typography/Text"
import { ConnectorDocument } from "@framework/types/content-manager"
import { IconName } from "@components/ui/Icon/IconName"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { useAppThemeManager } from "@components/theme/AppThemeProvider"
import { useAppConfig } from "@components/theme/AppConfigProvider"

import ContentInfo from "./ContentInfo"
import ContextMenu from "./ContextMenu"

import styles from "./ContentRow.module.sass"

type ContentData = {
  title: string
  icon: ReactNode
  info: ReactNode
}

export type ContentRowProps = {
  data: ConnectorDocument
}

const ContentRow: React.FC<ContentRowProps> = observer(({ data }) => {
  const [showMore, setShowMore] = useState(false)

  const {
    contentType,
    source,
    connectorName,
    connectedBy,
    lastModifiedDateTime,
    docInfo,
    id,
  } = data
  const docIcon = getDataSourceNodeContextProps(source)

  const rows: ContentData[] = useMemo(
    () => [
      {
        title: "Content Type",
        icon: contentType?.iconUrl ? (
          <Icon color="primary" name={contentType.iconUrl as IconName} />
        ) : null,
        info: contentType?.name,
      },
      {
        title: "Content Source",
        icon: docIcon && <DocumentIcon icon={docIcon.icon} />,
        info: source,
      },
      {
        title: "Content Connector",
        icon: <Icon name="storage" />,
        info: connectorName,
      },
      {
        title: "Connected By",
        icon: <Avatar name={connectedBy?.name} />,
        info: connectedBy?.name,
      },
    ],
    [
      contentType,
      source,
      connectorName,
      connectedBy,
      lastModifiedDateTime,
      docIcon,
    ]
  )
  const { activeColorSchemeName } = useAppThemeManager()
  const isDarkTheme = activeColorSchemeName === "Default Dark"
  const theme = useAppConfig()

  const moreRows: ContentData[] = useMemo(
    () =>
      Object.entries(docInfo).map(([key, value], index) => {
        const collapsed =
          (typeof value === "object" ? JSON.stringify(value) : `${value}`)
            ?.length > 500
        return {
          title: key,
          icon: null,
          info: !value ? null : (
            <ReactJson
              src={
                typeof value === "object" ? (value as any) : { [key]: value }
              }
              name={false}
              collapsed={collapsed}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize
              theme={isDarkTheme ? "google" : "rjv-default"}
              style={{
                background: "transparent",
                fontFamily: theme?.typography?.variables?.["main-font-family"],
                fontSize: "14px",
              }}
            />
          ),
        }
      }),
    [docInfo, isDarkTheme]
  )

  const allData = useMemo(
    () => [...rows, ...(showMore ? moreRows : [])],
    [rows, moreRows, showMore]
  )

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <Checkbox />
            <Text variant="body2">Document Id:</Text>
            <Text variant="body2" className={styles.documentId}>
              {id}
            </Text>
            <Chip color="green-solid" variant="rounded">
              New
            </Chip>
          </div>
          {/* hidden until API integration */}
          {/* <div className={styles.headerAction}>
            <ContextMenu />
          </div> */}
        </div>
        {allData.map((item, index) => (
          <ContentInfo
            key={item.title}
            isStriped={index % 2 === 0}
            title={item.title}
            icon={item.icon}
            info={item.info}
          />
        ))}
      </div>
      <div
        className={styles.expander}
        tabIndex={0}
        role="button"
        onClick={() => setShowMore(!showMore)}
        onKeyPress={() => {}}
      >
        <Icon name="arrow-down" rotateAngle={180} />
        <Icon name="arrow-down" />
      </div>
    </div>
  )
})

export default ContentRow
