import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"

import { FunctionDescription } from "../types"
import FormulaMarker from "../parser/FormulaMarker"

import styles from "./FunctionListItem.module.sass"

const FunctionListItem: React.FC<{
  item: FunctionDescription
}> = observer(({ item }) => {
  const renderArguments = () => {
    return item.arguments?.map((arg, index) => {
      return (
        <>
          {index !== 0 ? " , " : ""}
          <FormulaMarker type="argument" key={arg.displayName}>
            {arg.optional ? `[${arg.displayName}]` : arg.displayName}
          </FormulaMarker>
        </>
      )
    })
  }

  return (
    <List gutter="4">
      <Text variant="body2" className={styles.straightLine}>
        <FormulaMarker type="func-name">{item.name}</FormulaMarker>(
        {renderArguments()})
      </Text>
      <Text variant="caption2" color="text50Color">
        {item.description}
      </Text>
    </List>
  )
})

export default FunctionListItem
