import { makeAutoObservable } from "mobx"

import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"
import { Filename } from "@framework/types/company"
import { QueryFilterData } from "@framework/types/query-filter"
import {
  createMatrix,
  createMatrixFromSnapshot,
} from "@components/ui/Spreadsheet/createMatrix"
import {
  CellValidationRule,
  MatrixSnapshot,
} from "@components/ui/Spreadsheet/types"

import SimpleCollectionStore from "./simple-entity-collection.store"
import MatrixSnapshotStore from "./matrix-snapshot.store"
import UnifiedMatrixController from "./unified-matrix.controller"

export default class UnifiedMatrixStore {
  /**
   * @key solution ID
   */
  instances: Map<string, MatrixStore>

  filesStore: SimpleCollectionStore<Filename>

  productsStore: SimpleCollectionStore<QueryFilterData>

  snapshotStore: MatrixSnapshotStore

  isLoading: boolean = false

  error: string | null = null

  constructor() {
    this.instances = new Map()
    this.filesStore = new SimpleCollectionStore()
    this.productsStore = new SimpleCollectionStore()
    this.snapshotStore = new MatrixSnapshotStore()

    makeAutoObservable(this)
  }

  initInstance = async (
    solutionID: string,
    controller: UnifiedMatrixController,
    newInstancePreset?: MatrixSnapshot | null,
    initNew = true
  ) => {
    try {
      this.isLoading = true
      this.error = null

      const validationRules: Record<string, CellValidationRule> = {
        DROPDOWN_FILE: {
          type: "AUTOCOMPLETE",
          search: controller.fetchFiles,
        },
        DROPDOWN_PRODUCT: {
          type: "AUTOCOMPLETE",
          search: controller.fetchProducts,
        },
      }

      const snapshot = initNew
        ? newInstancePreset
        : this.snapshotStore.getSnapshot(solutionID)

      const newInstance =
        snapshot == null
          ? createMatrix({
              validationRules,
            })
          : createMatrixFromSnapshot({
              snapshot,
              validationRules,
            })

      this.instances.set(solutionID, newInstance)

      return newInstance
    } catch (error) {
      this.error = "Initialization failed"
    }

    return null
  }

  getMatrixInstance = (solutionID: string): MatrixStore | null => {
    return this.instances.get(solutionID) ?? null
  }

  dropInstance = (solutionID: string) => {
    this.instances.delete(solutionID)
  }
}
