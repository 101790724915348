import flatten from "lodash/flatten"

import { randomNumber } from "@utils/numberUtils"
import { delay } from "@utils/promise"

import { validateOperand } from "../utils"
import { FunctionDescription } from "../types"

const DEFAULT_FUNCTION_LIST: FunctionDescription[] = [
  {
    name: "SUM",
    description: "Sum of a series of numbers",
    arguments: [
      { displayName: "Number" },
      { displayName: "...Number", optional: true },
    ],
    handler: (...args: any[]) => args.reduce((acc, it) => acc + it, 0),
    transformArgs: (args: any[]) => {
      if (args.length < 1)
        throw new Error(`Function SUM expects at least one argument`)

      return flatten(args).map((it) => validateOperand(it))
    },
  },
  {
    name: "RANDOM",
    description: "Return random number in range",
    arguments: [
      { displayName: "Min", optional: true },
      { displayName: "Max", optional: true },
    ],
    handler: (...args: [number, number]) => randomNumber(...args),
    transformArgs: (args: any[]) => {
      if (args.length > 2)
        throw new Error("Function RANDOM expects two argument max")

      const min = Number(args[0] || 0)
      if (Number.isNaN(min))
        throw new Error("Function RANDOM expects number as a first argument")

      const max = Number(args[1] || 100)
      if (Number.isNaN(max))
        throw new Error("Function RANDOM expects number as a second argument")

      return [min, max]
    },
  },
  {
    name: "DELAY",
    description: "Return Value after wait Timeout (in milliseconds)",
    arguments: [{ displayName: "Timeout" }, { displayName: "Value" }],
    handler: async (...args: [number, any]) => {
      return delay(...args)
    },
    transformArgs: (args: any[]) => {
      if (args.length !== 2)
        throw new Error("Function DELAY expects two argument")

      const timeout = Number(args[0] || 1000)
      if (Number.isNaN(timeout))
        throw new Error("Function DELAY expects number as a first argument")

      return [timeout, args[1]]
    },
  },
]

export default DEFAULT_FUNCTION_LIST
