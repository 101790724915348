import {
  ExtendedResultType,
  SolutionTypes,
} from "@framework/constants/search-results"
import {
  AssignedGroupType,
  QuestionAssignityType,
} from "@framework/types/question"
import { WorkFlowType } from "@framework/types/solution"
import { DataSourceNodeName } from "@framework/types/upload"

export interface SimpleRouteType {
  (): string
}

type ID = string | number

export interface RouteWithIdType<T extends ID = ID> {
  (id: T): string
}

export interface ExtendedResultRoute {
  (id: string, tab: ExtendedResultType): string
}

const home: SimpleRouteType = () => `/home`

const solutionView: RouteWithIdType<string> = (type) => `/solutions/${type}`

const solutionEdit = (type: WorkFlowType, solutionId: string) =>
  `/solutions/${type}/edit/${solutionId}`

const solutionsFlowExtendedPassage = (
  solutionType: SolutionTypes,
  searchId: string,
  blockId: string,
  passageId: string,
  defaultTab?: ExtendedResultType
) =>
  `/solutions/${solutionType}/source/${searchId}/${blockId}/${passageId}/${defaultTab}`

const homeQuestions: RouteWithIdType = (tab) => `/home/questions/${tab}`

const profile: SimpleRouteType = () => "/profile"

const signUp: SimpleRouteType = () => "/sign-up"

const login: SimpleRouteType = () => "/login"

const loginConfirm: SimpleRouteType = () => "/confirm/login"

const signUpConfirm: SimpleRouteType = () => "/confirm/sign-up"

const changeEmail: SimpleRouteType = () => "/email-change"

const upload = (path: (DataSourceNodeName | string)[] = []): string => {
  const suffix = path.length ? `/${path.join("/")}` : ""
  return `/upload${suffix}`
}

const contentManager: SimpleRouteType = () => "/content-manager"

const uploadDataSource: RouteWithIdType<string> = (path) =>
  `/upload/${path}/add`

const updates: SimpleRouteType = () => "/updates"

const questions: SimpleRouteType = () => "/questions"

const question = (
  group: AssignedGroupType = "own",
  tab: QuestionAssignityType = "waiting",
  questionId?: string
) => `/questions/${group}/${tab}${questionId ? `/${questionId}` : ""}`

const analytics: SimpleRouteType = () => `/analytics`

const usageAnalytics: SimpleRouteType = () => `/analytics/usage`

const apiUsageAnalytics: SimpleRouteType = () => `/analytics/api-usage`

const experts: SimpleRouteType = () => "/expert"

const expertsMenu: SimpleRouteType = () => "/expert-menu"

const expertInsights: SimpleRouteType = () => "/expert-insights"

const adminPanel: SimpleRouteType = () => `/admin`

const adminPanelTab: RouteWithIdType = (tab) => `/admin/${tab}`

export default {
  home,
  solutionView,
  solutionEdit,
  homeQuestions,
  profile,
  signUp,
  login,
  loginConfirm,
  signUpConfirm,
  changeEmail,
  upload,
  uploadDataSource,
  updates,
  question,
  questions,
  analytics,
  usageAnalytics,
  apiUsageAnalytics,
  experts,
  expertsMenu,
  expertInsights,
  adminPanel,
  adminPanelTab,
  solutionsFlowExtendedPassage,
  contentManager,
}
